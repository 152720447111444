<header>
  <a routerLink="/">
    <img src="assets/images/logo.svg" alt="Reporting Business Solutions" />
  </a>

  <ciphr-auth-context-selector managerContextsOnly />

  <mypay-reporting-auth-panel></mypay-reporting-auth-panel>

  <button
    mat-icon-button
    class="nav-button"
    aria-label="Button with a hamburger icon to toggle navigation"
    data-test-id="navButton"
    (click)="openSidenav()"
  >
    <mat-icon>menu</mat-icon>
  </button>
</header>
