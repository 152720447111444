import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthContextState } from '@ciphr/core/auth-context/state';

export const canActiveManagerAccessGuard: CanActivateFn = () => verifyAccessGuard();

export const canMatchManagerAccessGuard: CanMatchFn = () => verifyAccessGuard();

const verifyAccessGuard = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectAuthContextState).pipe(
    filter((state) => state.contextsList.length > 0),
    map(({ managerContextsOnly, contextsList }) => {
      if (managerContextsOnly) {
        const mangerContext = contextsList.find((context) => !!context.adminUserLoginName) || null;
        if (!mangerContext) {
          router.navigate(['./unauthorized']);
          return false;
        }
      }
      return true;
    }),
  );
};
