<mat-sidenav-container
  class="sidenav-container"
  [hasBackdrop]="(desktopView$ | async) === false"
  (backdropClick)="closeNav()"
  [class.mat-drawer-desktop]="desktopView$ | async"
>
  <mat-sidenav *ngIf="accessItems$ | async" [opened]="opened$ | async" [mode]="(mode$ | async) || 'side'">
    <div>
      <div class="mobile-header">
        <a routerLink="/"> <img src="assets/images/logo.svg" alt="Ciphr Reporting Solution" /> </a>
        <button
          mat-icon-button
          [autofocus]="false"
          aria-label="An button with two crosses lines icon to close mobile navigation"
          (click)="closeNav()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-nav-list *ciphrAccessManagement="['MyPay.Reports']">
        <a mat-list-item routerLink="/viewer" routerLinkActive="active" data-test-id="navigateToReportViewer">Viewer</a>
        <a mat-list-item routerLink="/designer" routerLinkActive="active" data-test-id="navigateToReportDesigner">Designer</a>
      </mat-nav-list>
    </div>
    <div class="bottom-nav">
      <mat-nav-list *ngIf="(desktopView$ | async) === false">
        <a mat-list-item href="/">SSO Settings</a>
      </mat-nav-list>
      <mypay-reporting-auth-panel version="mobile"></mypay-reporting-auth-panel>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mypay-reporting-header></mypay-reporting-header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
