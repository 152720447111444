import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackBarData } from './snack-bar-data.type';
import { SnackBarTypeConfig } from './snack-bar-type-config.type';
import { snackBarTypeConfigsMap } from './snack-bar-type-configs.map';
import { SnackBarTypes } from './snack-bar-types.enum';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatButtonModule],
})
export class SnackBarComponent {
  @HostBinding('class') class = this.typeConfig.class;

  get typeConfig(): SnackBarTypeConfig {
    return snackBarTypeConfigsMap[this.data.type];
  }

  get closable(): boolean {
    return this.data.type !== SnackBarTypes.Success;
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData, private snackBarRef: MatSnackBarRef<SnackBarComponent>) {}

  dismissWithAction(): void {
    this.snackBarRef.dismissWithAction();
  }
}
