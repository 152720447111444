import { APP_INITIALIZER, ErrorHandler, InjectionToken, isDevMode, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideRouter, withRouterConfig } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  AccessManagementApiDataAdapterModule,
  AccessManagementApiDataAdapterService,
} from '@ciphr/shared/access-management/api-data-adapter';
import { AccessManagementStateModule } from '@ciphr/shared/access-management/state';
import { ApiConfig, provideIsolatedHttpApiClient } from '@ciphr/utils/data-adapters';
import { appConfigInitializerFactory } from '@ciphr/core/app-config';
import { AuthContextApiDataAdapterModule, AuthContextApiDataAdapterService } from '@ciphr/core/auth-context/api-data-adapter';
import { authContextInterceptor } from '@ciphr/core/auth-context/features';
import { AuthContextStateModule } from '@ciphr/core/auth-context/state';
import { AuthModule, OAuthInterceptor } from '@ciphr/core/auth';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NavigationComponent } from './navigation/navigation.component';
import { ReportingErrorHandler } from './core/error-handler';
import { UiEffects } from './state/ui/ui.effects';
import { uiReducer } from './state/ui/ui.reducer';

export const REPORTING_API_CONFIG = new InjectionToken<ApiConfig>('Reporting Api Config');
export const REPORTING_HTTP_CLIENT = new InjectionToken<HttpClient>('Reporting Http Client');

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    EffectsModule.forRoot([UiEffects]),
    StoreModule.forRoot({ ui: uiReducer }),
    AccessManagementApiDataAdapterModule.withConfig({ apiConfig: { name: 'mypay', version: 1 }, interceptors: [authContextInterceptor] }),
    AccessManagementStateModule.withConfig(AccessManagementApiDataAdapterService, ReportingErrorHandler),
    AuthContextApiDataAdapterModule.withConfig({ apiConfig: { name: 'mypay', version: 1 } }),
    AuthContextStateModule.withConfig(AuthContextApiDataAdapterService, ReportingErrorHandler),
    AuthModule.withConfig('mypay-reporting', {
      issuer: isDevMode() ? 'https://dev.ciphr.com' : window.location.origin,
      redirectUri: window.location.origin + '/mypay-reporting/app',
      responseType: 'code',
      scope: 'openid profile email offline_access https://api.mypay.ciphr.com/.default https://api.mypay-reporting.ciphr.com/.default',
      sessionChecksEnabled: false,
    }),
    NavigationComponent,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    provideIsolatedHttpApiClient({
      apiConfig: { name: 'mypay-reporting', version: 1 },
      interceptors: [authContextInterceptor],
      token: REPORTING_HTTP_CLIENT,
    }),
    provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' })),
    { provide: APP_INITIALIZER, useFactory: appConfigInitializerFactory, multi: true },
    { provide: ErrorHandler, useClass: ReportingErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: { diameter: 40 } },
    { provide: REPORTING_API_CONFIG, useValue: { name: 'mypay-reporting', version: 1 } },
  ],
})
export class AppModule {}
