import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { SnackBarService } from '@ciphr/material';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ReportingErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: unknown) {
    const ngZone = this.injector.get(NgZone);
    ngZone.run(() => {
      if (error instanceof HttpErrorResponse) {
        this.showErrorSnackbar(error);
      } else {
        console.error(error);
      }
    });
  }

  private showErrorSnackbar({ error }: HttpErrorResponse): void {
    const snackBarService = this.injector.get(SnackBarService);

    if (error?.details) {
      const message = error.details.length ? error.details.map((err: any) => err.message) : [error.message];
      snackBarService.error(message[0]);
    } else {
      snackBarService.error('An error has occurred.');
    }
  }
}
