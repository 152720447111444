<ng-container *ngIf="userName">
  <div class="user-container">
    <div class="label">
      <span class="prefix">{{ initials }}</span>
    </div>
    <div class="credential">
      <p class="full-name">{{ firstNameWithLastName }}</p>
    </div>
  </div>
  <div class="logout-container" [class]="version">
    <ng-container *ngIf="version === 'desktop'">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="An icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a href="/" mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>SSO Settings</span>
        </a>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </ng-container>
    <button
      *ngIf="version === 'mobile'"
      mat-button
      color="primary"
      aria-label="A button with a door icon to logout user"
      data-test-id="logoutButton"
      (click)="logout()"
    >
      <mat-icon>logout</mat-icon>
      <span class="description">Logout</span>
    </button>
  </div>
</ng-container>
