import { Routes } from '@angular/router';
import { canMatchAuthGuard } from '@ciphr/core/auth';
import { canMatchManagerAccessGuard } from './core/manager-access-guard';
import { canMatchAccessManagementGuard } from '@ciphr/shared/access-management/features';

export const appRoutes: Routes = [
  {
    path: 'viewer',
    canMatch: [canMatchAuthGuard, canMatchManagerAccessGuard, canMatchAccessManagementGuard(['MyPay.Reports'])],
    loadComponent: () =>
      import('./report-viewer-container/report-viewer-container.component').then((c) => c.ReportViewerContainerComponent),
    title: 'CIPHR Reporting - Report Viewer Page',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'designer',
    canMatch: [canMatchAuthGuard, canMatchManagerAccessGuard, canMatchAccessManagementGuard(['MyPay.Reports'])],
    loadChildren: () => import('./report-designer-container/report-designer.module').then((m) => m.ReportDesignerModule),
    title: 'CIPHR Reporting - Report Designer Page',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('./unauthorized/unauthorized.component').then((c) => c.UnauthorizedComponent),
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'viewer',
  },
];
