import { Component, HostListener, inject, OnInit } from '@angular/core';
import { selectActiveContext } from '@ciphr/core/auth-context/state';
import { filter } from 'rxjs';
import { accessManagementFeatureActions } from '@ciphr/shared/access-management/state';
import { Store } from '@ngrx/store';
import { AuthService } from '@ciphr/core/auth';
import { selectPageContentVisibility } from './state/ui/ui.selectors';

@Component({
  selector: 'mypay-reporting-root',
  template: `
    <mypay-reporting-navigation *ngIf="isPageContentVisible$ | async; else loaderBlock" class="page-container"></mypay-reporting-navigation>
    <ng-template #loaderBlock><mat-spinner></mat-spinner></ng-template>
  `,
  styles: [
    `
      mat-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  private authService = inject(AuthService);

  isPageContentVisible$ = this.store.select(selectPageContentVisibility);

  @HostListener('window:pageshow', ['$event'])
  reloadIfLoggedOut(event: any) {
    if (event.persisted) {
      this.authService.initLogin();
    }
  }
  ngOnInit(): void {
    this.store
      .select(selectActiveContext)
      .pipe(filter(Boolean))
      .subscribe(() => this.store.dispatch(accessManagementFeatureActions.loadAccessItems()));
  }
}
